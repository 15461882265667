import React from 'react';
import { Link } from 'react-router-dom';
import { IKImage } from 'imagekitio-react';
import '../styles/Footer.css'
import '@fortawesome/fontawesome-free/css/all.css'

const Footer = () => {
  const handleEmailClick = () => {
    window.location.href = 'mailto:info@npfd1.com';
  };
  const handleNPFEmailClick = () => {
    window.location.href = 'mailto:npfmembershipdrive@gmail.com';
  };
  const handleLocationClick = () => {
    window.open('https://shorturl.at/mnpU7', '_blank');
  };
  const handleNpfLocationClick = () => {
    window.open('https://shorturl.at/F1wRk', '_blank');
  };
  return (
    <footer className="text-center text-lg-start text-white" style={{ backgroundColor: '#3B75B2' }}>
      <div className="container p-4 pb-0">
        <section className="mb-4">
          <div className="row">
            <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
            <h4>A Joint Venture Project of</h4><br/><br/><br/>
              <h6 className="text-uppercase mb-4 font-weight-bold">
             
                <div className="imagesfooter" style={{ display: 'flex', }}>
              <IKImage
    path="/npf.png?updatedAt=1720032907741" 
    publicKey= 'public_qNiTcGcX9mFOfedWVQzxsR5iKJE='
  urlEndpoint= 'https://ik.imagekit.io/urafoew8x' 
  style={{ width: '180px', height: '180px', marginTop: '-50px'  }}
                  className="logo" />
              <IKImage
    path="/D1logo.png.png?updatedAt=1708775472098" s
    publicKey= 'public_ai3DHWL78cix/8pydFCCczQwpqY='
  urlEndpoint= 'https://ik.imagekit.io/HamzaMalik' 
  style={{ width: '200px', height: '200px', marginTop: '-50px' }}
                  className="logo" /></div>
              </h6>
              <hr className="w-100 clearfix d-md-none" />

              <p>
              A Housing Establishment that is proactive and future-oriented. We embrace change and our community development strategies are guided by a strong vision.</p>
                <p>Developed By:</p><p> NPF-D1 Capital Park City  <span>(IT Department)</span></p>
                
                
            </div>

            <hr className="w-100 clearfix d-md-none" />

            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
              <h6 className="text-uppercase mb-4 font-weight-bold">Offices</h6>
              <p><a href="https://shorturl.at/F1wRk" className="text-white" role="button" target="_blank" rel="noopener noreferrer">NPF Plaza, G-10/4, Islamabad </a></p>
              <p><a href="https://shorturl.at/mnpU7" className="text-white" role="button" target="_blank" rel="noopener noreferrer">Blue Area Islamabad</a></p>
              <p><a href="https://rb.gy/7lvjro" className="text-white" role="button" target="_blank" rel="noopener noreferrer">Model Town Corporate Office Lahore</a></p>
              
            </div>

            <hr className="w-100 clearfix d-md-none" />

            <div className="col-md-2 col-lg-3 col-xl-2 mx-auto mt-3">
              <h6 className="text-uppercase mb-4 font-weight-bold">Useful links</h6>
              <p><a  href="https://shorturl.at/F1wRk" className="text-white" role="button" target="_blank" rel="noopener noreferrer">NPF-D1 Office Location</a></p>
              <p><a  href="https://shorturl.at/mnpU7" className="text-white" role="button" target="_blank" rel="noopener noreferrer">Main Office Location</a></p>
              <p><a href="https://shorturl.at/cnBQY" className="text-white" role="button" target="_blank" rel="noopener noreferrer">Site Location</a></p>
              <p><a href="https://phataahs.punjab.gov.pk/HousingSocietiesDetail?kSXRatIfPRejaF2/jeY2YA==" className="text-white" role="button" target="_blank" rel="noopener noreferrer">Phata Approved</a></p>
              <Link to="/contact-us">
              <p><a  className="text-white" >Help</a></p></Link>
            </div> 

            <hr className="w-100 clearfix d-md-none" />

            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
              <h6 className="text-uppercase mb-4 font-weight-bold">Contact Info</h6>
             <p onClick={handleLocationClick} style={{ cursor: 'pointer' }}>
                <i className="fas fa-home mr-3"></i> Jinnah Avenue, Fortune Plaza, Savour Foods Building G-7/2 Blue Area, Islamabad</p>
              <p onClick={handleEmailClick} style={{ cursor: 'pointer' }}>
        <i className="fas fa-envelope mr-3"></i> info@npfd1.com
      </p>
              <p><i className="fas fa-phone mr-3"></i> 051-111-31-00-31</p>
              
            </div>
            <hr className="w-100 clearfix d-md-none" />

            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
              <h6 className="text-uppercase mb-4 font-weight-bold">NPFD1 Contact Info</h6>
              <p onClick={handleNpfLocationClick} style={{ cursor: 'pointer' }}>
                <i className="fas fa-home mr-3"></i> National Police Foundation NPF Plaza, G-10/4, Islamabad.</p>
             <p onClick={handleNPFEmailClick} style={{ cursor: 'pointer' }}>
        <i className="fas fa-envelope mr-3"></i> npfmembershipdrive@gmail.com
      </p>
              <p><i className="fas fa-phone mr-3"></i> 051-9106171-2</p>
              
            </div>
          </div>
        </section>

        <hr className="my-3" />

        <section className="p-3 pt-0">
          <div className="row d-flex align-items-center">
            <div className="col-md-7 col-lg-8 text-center text-md-start">
              <div className="p-3">
                © 2024 Copyright: &nbsp;
                <a className="text-white" href="#">NPF-D1CAPITALPARKCITY</a>
              </div>
            </div>

            <div className="col-md-5 col-lg-4 ml-lg-0 text-center text-md-end">
  <a href="https://www.facebook.com/d1capitalparkcityisb?mibextid=kFxxJD" className="btn btn-outline-light btn-floating m-1" role="button" target="_blank" rel="noopener noreferrer">
    <i className="fab fa-facebook-f"></i>
  </a>
  <a href="https://twitter.com/D1CAPITALPARK" className="btn btn-outline-light btn-floating m-1" role="button" target="_blank" rel="noopener noreferrer">
    <i className="fab fa-twitter"></i>
  </a>
  <a href="https://www.youtube.com/@d1capitalparkcityisb" className="btn btn-outline-light btn-floating m-1" role="button" target="_blank" rel="noopener noreferrer"> 
  <i className="fab fa-youtube"></i>
</a>
  <a href="https://www.instagram.com/d1capitalparkcity?igsh=MXZkcXAwY20yeDZreg==" className="btn btn-outline-light btn-floating m-1" role="button" target="_blank" rel="noopener noreferrer">
    <i className="fab fa-instagram"></i>
  </a>
  <a href="mailto:info@npfd1.com" className="btn btn-outline-light btn-floating m-1" role="button" target="_blank" rel="noopener noreferrer">
    <i className="fas fa-envelope"></i> 
  </a>
  <a href="https://wa.me/+923341111773" className="btn btn-outline-light btn-floating m-1" role="button" target="_blank" rel="noopener noreferrer">
    <i className="fab fa-whatsapp"></i>
  </a>
</div>

          </div>
        </section>
      </div>
    </footer>
  );
};

export default Footer;
