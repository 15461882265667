import React, { lazy,useEffect, Suspense } from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import phata from '../assets/phata.gif';
import '../styles/Faqs.css';
import ReactLoading from '../assets/React-Loading.gif';
import Whatsapp from '../components/FloatingWhatsapp.js';
import {Helmet} from 'react-helmet-async';

const LazyFaqsQuestion = lazy(() => import('../components/FAQs/FaqsQuestion'));

const FAQS = () => {

  useEffect(() => {
    document.title = 'NPF-D1 Capital Park City | FAQs';
  },[]);

  return (
    <div className='Faqs-container'>
      <Helmet>
        <title>NPF-D1 Capital Park City | FAQs</title>
        <meta name="description" content="Frequently Asked Questions."/>
        <link rel='canonical' href='https://d1capitalparkcity.com/FAQs'/>
      </Helmet>
   <Suspense fallback={<div style={{ backgroundColor:"#000", width:"100%", height:"100vh" }}><img style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', padding: '20px', borderRadius: '8px' }} src={ReactLoading} alt="Loading..." /></div>}>
          <div >
        <Header/>
        <div className="phata">
          <img src={phata} alt="Phata" loading="lazy" />
        </div>
      </div>
      <div className='Faqs'>
        
        <h1>FREQUENTLY ASKED QUESTION (FAQs)</h1>
      </div>



<div className='faqquestion'>
<LazyFaqsQuestion/>
</div>



      <Whatsapp/>
      <div>
        <Footer/>
      </div>
</Suspense>
    </div>
  )
}

export default FAQS
