import React from 'react';
import './Pricepopup.css';



const Pricepopup = ({ onClose }) => {

  return (
    <div className="pricepopup-container" >
      <button className="priceclose-button" onClick={onClose}>X</button> {/* Close button */}
      <div className="pricepopup-content" >
      <h1 style={{fontSize:'30px', color:'red'}}><b>Limited Time Offer!</b> </h1>
        <h1>NPF-D1 Capital Park City</h1>
        <h3>Special Discount For Police/NPF Members</h3>
        
    <button className='btn41-43 btn-43' onClick={onClose}>Close</button>

       
      </div>
    </div>
  );
};

export default Pricepopup;
