import React, { Suspense, useEffect }from 'react';
import '../styles/Gallery.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import phata from '../assets/phata.gif';
import Whatsapp from '../components/FloatingWhatsapp.js';
import ReactLoader from '../assets/React-Loading.gif';
import { IKImage } from 'imagekitio-react';
import {Helmet} from 'react-helmet-async';

const ImageKitConfig = {
  publicKey: 'public_ai3DHWL78cix/8pydFCCczQwpqY=',
  urlEndpoint: 'https://ik.imagekit.io/HamzaMalik',
};



const Gallery = () => {

  useEffect(() => {
    document.title = 'NPF-D1 Capital Park City | Gallery';
  },[]);

  useEffect(() => {
    const images = document.querySelectorAll('.image-container img');
  
    images.forEach((img) => {
      img.onload = () => {
     
        img.style.opacity = 1; // Show the image container after the image is loaded
      };
    });
  }, []);


  return (
    <div>
       <Helmet>
        <title>NPF-D1 Capital Park City | Gallery</title>
        <meta name="description" content="NPF-D1 Capital Park City (The City of Innovations & Tourism)."/>
        <link rel='canonical' href='https://d1capitalparkcity.com/gallery'/>
      </Helmet>
    <Suspense fallback={<div style={{ backgroundColor:"#000", width:"100%", height:"100vh" }}><img style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', padding: '20px', borderRadius: '8px' }} src={ReactLoader} alt="Loading..." /></div>}>
      <div>
        <Header/>
        <div className="phata">
          <img src={phata} alt="Phata" loading="lazy" />
        </div>
      </div>

   <h1 className='h1stake'>OUR GALLERY</h1>

   <div className='Gallery'>
    


   <div className="image-container">
   <IKImage
    path="/Gallery13.png?updatedAt=1708757858998" 
    publicKey= {ImageKitConfig.publicKey}
    urlEndpoint= {ImageKitConfig.urlEndpoint}
/>
      <div className="image-info">
            <h2>Bird Eye View </h2>
            <p>The Bird Eye View of the NPF-D1 Capital Park City.</p>
          </div>
         
      </div>

   <div className="image-container">
   <IKImage
    path="/Gallery9.png?updatedAt=1708757853565" 
    publicKey= {ImageKitConfig.publicKey}
    urlEndpoint= {ImageKitConfig.urlEndpoint}
/>
      <div className="image-info">
            <h2>Garden By The Bay </h2>
            <p>The "Garden By The Bay" concept is first introduce by NPF-D1 Capital Park City in Pakistan, inspired by the Singapore.</p>
          </div>    
      </div>

      <div className="image-container">
 <IKImage
    path="/Gallery12.png?updatedAt=1708757860900" 
    publicKey= 'public_ai3DHWL78cix/8pydFCCczQwpqY='
  urlEndpoint= 'https://ik.imagekit.io/HamzaMalik'  
/>    
    <div className="image-info">
            <h2>Artificial Rain Forest</h2>
            <p>NPF-D1 Capital Park City is bringing the new concept of Artificial Rain Forest in Pakistan.</p>
          </div> 
      </div>


      <div className="image-container">
      <IKImage
    path="/Gallery3.png?updatedAt=1708757854252" 
    publicKey= 'public_ai3DHWL78cix/8pydFCCczQwpqY='
  urlEndpoint= 'https://ik.imagekit.io/HamzaMalik'  
/>   
      <div className="image-info">
            <h2>Theme Lake Park</h2>
            <p>NPF-D1 Capital Park City also have the Natural Theme Lake Park.</p>
          </div>
      </div>




      <div className="image-container">
      <IKImage
    path="/Gate%20close%20shorts_00385.png?updatedAt=1724950512772" 
    publicKey= 'public_qNiTcGcX9mFOfedWVQzxsR5iKJE='
  urlEndpoint= 'https://ik.imagekit.io/urafoew8x'  
/>   
      <div className="image-info">
            <h2>NPF-D1 Main Gate</h2>
            <p>Main gate of NPF-D1 Capital Park City.</p>
          </div>
      </div>

{/*
   <div className="image-container">
   <IKImage
    path="/Gallery1.png?updatedAt=17087578536122" 
    publicKey= 'public_ai3DHWL78cix/8pydFCCczQwpqY='
  urlEndpoint= 'https://ik.imagekit.io/HamzaMalik'  
/>  
          <div className="image-info">
            <h2>NPF-D1 Modern Monument</h2>
            <p>Experience the Architectural Marvel of the NPF-D1 Capital Park City Monument</p>
          </div>
        </div>*/}

        <div className="image-container">
        <IKImage
    path="/Gallery2.png?updatedAt=1708757854907" 
    publicKey= 'public_ai3DHWL78cix/8pydFCCczQwpqY='
  urlEndpoint= 'https://ik.imagekit.io/HamzaMalik'  
/>  
          <div className="image-info">
            <h2>Modern Monument</h2>
            <p>NPF-D1 Capital Park City Monument is a landmark devoted to innovative and futuristic ideologies.</p>
          </div>
        </div>


      <div className="image-container">
 <IKImage
    path="/Gallery4.png?updatedAt=1708757853751" 
    publicKey= 'public_ai3DHWL78cix/8pydFCCczQwpqY='
  urlEndpoint= 'https://ik.imagekit.io/HamzaMalik'  
/>        
<div className="image-info">
            <h2>Beautiful NPF-D1 Family Park</h2>
            <p>NPF-D1 Capital Park City also include the Beautiful Parks that make it look like a heaven for the Tourists.</p>
          </div>
      </div>

      <div className="image-container">
      <IKImage
    path="/Gallery5.png?updatedAt=1708757853409" 
    publicKey= 'public_ai3DHWL78cix/8pydFCCczQwpqY='
  urlEndpoint= 'https://ik.imagekit.io/HamzaMalik'  
/> 
      <div className="image-info">
            <h2>Modern Monument With Beautiful View</h2>
            <p>NPF-D1 Monument Park with the beautiful view.</p>
          </div>
      </div>

      <div className="image-container">
 <IKImage
    path="/Gallery6.png?updatedAt=1708757854027" 
    publicKey= 'public_ai3DHWL78cix/8pydFCCczQwpqY='
  urlEndpoint= 'https://ik.imagekit.io/HamzaMalik'  
/> 
      <div className="image-info">
            <h2>Light Tower</h2>
            <p>The Light Tower stands tall as a beacon of elegance and charm, illuminating the park of NPF-D1 with its graceful presence.</p>
          </div>
      </div>

      <div className="image-container">
      <IKImage
    path="/Gallery7.png?updatedAt=1708757853703" 
    publicKey= 'public_ai3DHWL78cix/8pydFCCczQwpqY='
  urlEndpoint= 'https://ik.imagekit.io/HamzaMalik'  
/> 
      <div className="image-info">
            <h2>NPF-D1 Commercial Hub</h2>
            <p>NPF-D1 Commercial Market become the Hub of all the Brands.</p>
          </div>
      </div>

      <div className="image-container">
      <IKImage
    path="/Gallery8.png?updatedAt=1708757854674" 
    publicKey= 'public_ai3DHWL78cix/8pydFCCczQwpqY='
  urlEndpoint= 'https://ik.imagekit.io/HamzaMalik'  
/> 
      <div className="image-info">
            <h2>NPF-D1 Commercial Hub</h2>
            <p>Another View of NPF-D1 Commercial Hub.</p>
          </div>
      </div>

    

      <div className="image-container">
      <IKImage
    path="/Gallery10.png?updatedAt=1708757854042" 
    publicKey= 'public_ai3DHWL78cix/8pydFCCczQwpqY='
  urlEndpoint= 'https://ik.imagekit.io/HamzaMalik'  
/> 
      <div className="image-info">
            <h2>NPF-D1 Commercial Hub</h2>
            <p>NPF-D1 Commercial Market with its unique styles and beauty.</p>
          </div>
      </div>

      <div className="image-container">
      <IKImage
    path="/Gallery11.png?updatedAt=1708757858528" 
    publicKey= 'public_ai3DHWL78cix/8pydFCCczQwpqY='
  urlEndpoint= 'https://ik.imagekit.io/HamzaMalik'  
/>   
    <div className="image-info">
            <h2>NPF-D1 Sports Park</h2>
            <p>NPF-D1 Capital Park City boasts the impressive NPF-D1 Sports Park, offering versatile facilities for basketball, cricket, and various other sports.</p>
          </div>
      </div>




      <div className="image-container">
      <IKImage
    path="/Gallery15.png?updatedAt=1708757859826" 
    publicKey= 'public_ai3DHWL78cix/8pydFCCczQwpqY='
  urlEndpoint= 'https://ik.imagekit.io/HamzaMalik'  
/>  
      <div className="image-info">
            <h2>Artificial Rain Forest</h2>
            <p>This is the beautiful outer look of NPF-D1 Artificial Rain Forest.</p>
          </div>
      </div>


      <div className="image-container">
      <IKImage
    path="/Gallery16.png?updatedAt=1708757861332" 
    publicKey= 'public_ai3DHWL78cix/8pydFCCczQwpqY='
  urlEndpoint= 'https://ik.imagekit.io/HamzaMalik'  
/>  
      <div className="image-info">
            <h2>Artificial Rain Forest</h2>
            <p>NPF-D1 Capital Park City Artificial Rain Forest.</p>
          </div>
      </div>


      <div className="image-container">
      <IKImage
    path="/Gallery17.png?updatedAt=1708757860926" 
    publicKey= 'public_ai3DHWL78cix/8pydFCCczQwpqY='
  urlEndpoint= 'https://ik.imagekit.io/HamzaMalik'  
/>  
      <div className="image-info">
            <h2>NPF-D1 Sports Park </h2>
            <p>NPF-D1 Capital Park City include the NPF-D1 Sports Park which includes the beautiful Basketball ground, Cricket ground.</p>
          </div>
      </div>

      <div className="image-container">
      <IKImage
    path="/Gallery18.png?updatedAt=1708757861465" 
    publicKey= 'public_ai3DHWL78cix/8pydFCCczQwpqY='
  urlEndpoint= 'https://ik.imagekit.io/HamzaMalik'  
/> 
      <div className="image-info">
            <h2>Beautiful NPF-D1 Family Park</h2>
            <p>NPF-D1 Capital Park City also include the Beautiful Parks that make it look like a heaven for the Tourists.</p>
          </div>
      </div>


    </div>





    

<Whatsapp/>
      <div>
        <Footer/>
       
      </div>
      </Suspense>
 
    </div>
  );
};

export default Gallery;
