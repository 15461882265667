import React, { Suspense, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import Header from '../components/Header';
import Popup from '../components/Homepage/Popup';
import '../styles/Homepage.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import specialoffer from '../assets/BOOKINGBLUE2.png';
import preloader from '../assets/preloader.gif';
import phata from '../assets/phata.gif';
import { IKImage } from 'imagekitio-react';
import ReactLoading from '../assets/React-Loading.gif';
import {Helmet} from 'react-helmet-async';

// Lazy load Smartfacilities and FloatingWhatsapp components
const LazySmartfacilities = React.lazy(() => import('../components/Homepage/Smartfacilities'));
const LazyFloatingWhatsapp = React.lazy(() => import('../components/FloatingWhatsapp'));

const Homepage = () => {
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(true);

  

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    document.title = 'NPF-D1 Capital Park City | Home';
  },[]);

  return (
    <div className="homepage-container">
      <Helmet>
        <title>NPF-D1 Capital Park City | Home</title>
        <meta name="description" content="Discover luxury living near Islamabad Airport at NPF-D1 Capital Park City. Modern homes, serene community, prime location."/>
        <link rel='canonical' href='https://d1capitalparkcity.com/'/>
      </Helmet>
     
        <Suspense fallback={<div style={{ backgroundColor: "#000", width: "100%", height: "100vh" }}><img style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', padding: '20px', borderRadius: '8px' }} src={ReactLoading} alt="Loading..." /></div>}>
          <Header />
          <div className="Header">
            <div className='specialoffer'>
              <Link to="/pricing">
                <img src={specialoffer} alt='special offer' />
              </Link>
            </div>
            <div className="phata">
              <img src={phata} alt="Phata" />
            </div>
          </div>
          <div className="slider-container">
            <Slider {...sliderSettings}>
              <div>
                <IKImage
                  path="/Gate%20New_00105.png?updatedAt=1724950715540"
                  publicKey='public_qNiTcGcX9mFOfedWVQzxsR5iKJE='
                  urlEndpoint='https://ik.imagekit.io/urafoew8x'
                />
              </div>
              <div>
                <IKImage
                  path="/Slider2.png?updatedAt=1708761318510"
                  publicKey='public_ai3DHWL78cix/8pydFCCczQwpqY='
                  urlEndpoint='https://ik.imagekit.io/HamzaMalik'
                />
              </div>
              <div>
                <IKImage
                  path="/Slider3.png?updatedAt=1708761318604"
                  publicKey='public_ai3DHWL78cix/8pydFCCczQwpqY='
                  urlEndpoint='https://ik.imagekit.io/HamzaMalik'
                />
              </div>
              <div>
                <IKImage
                  path="/Slider4.png?updatedAt=1708761318495"
                  publicKey='public_ai3DHWL78cix/8pydFCCczQwpqY='
                  urlEndpoint='https://ik.imagekit.io/HamzaMalik'
                />
              </div>
            </Slider>
          </div>
          <div className="smartservices">
            <LazySmartfacilities />
          </div>
          {showPopup && <Popup onClose={handleClosePopup} />}
          <LazyFloatingWhatsapp />
        </Suspense>
   
    </div>
  );
};

export default Homepage;
