import React, { useState, useEffect, Suspense, lazy, useMemo } from 'react';
import Header from '../components/Header';
import phata from '../assets/phata.gif';
import Footer from '../components/Footer';
import '../styles/Contactus.css';
import ReactLoading from '../assets/React-Loading.gif';
import {Helmet} from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

const LazyFloatingWhatsapp = lazy(() => import('../components/FloatingWhatsapp'));


const Contactus = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [phonenumber, setPhonenumber] = useState('');

  const location=useLocation();
  const { selectedPlotSize } = location.state || {};

  const messageTemplate = useMemo(() => {
    if (!selectedPlotSize) return '';
    return `Selected Plot Size: ${selectedPlotSize}`;
  }, [selectedPlotSize]);
  
  useEffect(() => {
    document.title = 'NPFD-1 Capital Park City | Contact Us';
  },[]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const defaultMessage = "From: NPF-D1 Capital Park City";
  
  // Combine the default message with the user's message
    const Message =message +'\n' + messageTemplate+'\n' +defaultMessage;

    const currentDate = new Date();
    const timestamp = currentDate.toLocaleString();
    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Phone number validation regex (for Pakistani numbers)
    const phoneRegex = /^(?:\+?92|\(?0?\d{2}\)?)\d{9,10}$/    ;

   


    // Check if email and phone number are in correct format
    if (!emailRegex.test(email)) {
      alert('Please enter a valid email address');
      return;
    }

    if (!phoneRegex.test(phonenumber)) {
      alert('Please enter a your valid phone/mobile number.');
      return;
    }

    const formData = {
      Name: name,
      Email: email,
      PhoneNumber: phonenumber,
      Message: Message,
      Time: timestamp 
    };
    try {
      const response = await fetch('https://sheetdb.io/api/v1/8ko38jsh7t4v9', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      
      if (response.ok) {
        alert('Your Interest has been Submitted Successfully! Our Team will contact you Shortly! ');
        setName('');
        setEmail('');
        setPhonenumber('');
        setMessage('');

      } else {
        alert('We are sorry! We could not process your request at the moment! Try again Later!.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Sorry! We are facing some techinical isssues for the moment!');
    }
  };

  return (
    <div className="contactus-container">
      <Helmet>
        <title>NPF-D1 Capital Park City | Contact Us</title>
        <meta name="description" content="Our helpline is always open to receive any inquiry or feedback."/>
        <link rel='canonical' href='https://d1capitalparkcity.com/contact-us'/>
      </Helmet>
    <Suspense fallback={<div style={{ backgroundColor:"#000", width:"100%", height:"100vh" }}><img style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', padding: '20px', borderRadius: '8px' }} src={ReactLoading} alt="Loading..." /></div>}>
      <Header />
      <div className="phata">
        <img src={phata} alt="Phata" />
      </div>
      <div className="contactus-content">
        <h1 className="contacth1stake">Contact Us </h1>
        <div className="contact-form-container">
          <div className="Contactus-form">
            <form onSubmit={(e)=>handleSubmit(e)}>
              <label className="animated-label">
                Name: 
                <input
                  type="text"
                  placeholder="Please Enter your name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  maxLength={40} 
                  required
                />
              </label>
              <br />
              <label className="animated-label">
                Email: 
                <input
                  type="email"
                  placeholder="Please Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </label>
              <br />
              <label className="animated-label">
                Phone Number:
                <input
                  type="number"
                  placeholder="Please Enter your phone/mobile number"
                  value={phonenumber}
                  onChange={(e) => setPhonenumber(e.target.value)}
                  required
                />
              </label>
             
              <br />
              <label className="animated-label">
                Message:
                <textarea
                  value={message}
                  placeholder="Enter your message(Optional)"
                  onChange={(e) => setMessage(e.target.value)}
                />
              </label>
              <br/>
             {/* <label className="animated-label">
                Pre-Filled Message:
                <textarea
                  value={messageTemplate}
                  placeholder="No Message to Shown."
                  readOnly={true}
                style={{ height: 'auto', lineHeight: 'normal', resize: 'vertical' }}
                />
              </label>
  <br />*/}
              <button className='btn41-43 btn-43' type="submit">Submit Your Interest</button>
              <br/><br/>
            </form>
          </div>
          <div className="additional-content">
          <div>
              <h1>Contact Info</h1>
              <p className='additional-para'>Feel free to reach out to us! Our dedicated team is here to assist you with any inquiries, feedback, or information you may need. Whether you have questions about our services, want to discuss potential collaborations, or simply want to say hello, we welcome your communication. You can contact us via the form on this page, drop us an email, or connect with us through our social media channels. Your input is valuable to us, and we look forward to hearing from you. Thank you for considering us as your point of contact – we're eager to connect and engage with you!</p>
            </div>
            <span>
            <hr/><h4>NPF-D1 Address</h4>
              <p>National Police Foundation NPF Plaza, G-10/4, Islamabad.</p>
            </span>
            <span>
              <h4 style={{ display: 'inline-block', marginRight: '10px' }}>UAN:</h4>
              <p style={{ display: 'inline-block', fontSize:'20px' }}>051-9106171-2</p>
            </span>
            
            <span>
            <hr/>
              <h4>D1 Address</h4>
              <p>Jinnah Avenue, Fortune Plaza, Savor Foods Building, G-7/2 Blue Area, Islamabad.</p>
            </span>
            <span>
              <h4 style={{ display: 'inline-block', marginRight: '10px' }}>UAN:</h4>
              <p style={{ display: 'inline-block', fontSize:'20px' }}>051-111-31-00-31</p>
            </span>
            <h4>Social Media Platforms</h4>
            <div className="col-md-5 col-lg-4 ml-lg-0 text-center text-md-end social-icons">
              <a href="https://www.facebook.com/d1capitalparkcityisb?mibextid=kFxxJD" className="btn btn-outline-light btn-floating m-1" role="button" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="https://twitter.com/D1CAPITALPARK" className="btn btn-outline-light btn-floating m-1" role="button" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="https://www.youtube.com/@d1capitalparkcityisb" className="btn btn-outline-light btn-floating m-1" role="button" target="_blank" rel="noopener noreferrer"> 
                <i className="fab fa-youtube"></i>
              </a>
              <a href="https://www.instagram.com/d1capitalparkcity?igsh=MXZkcXAwY20yeDZreg==" className="btn btn-outline-light btn-floating m-1" role="button" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="mailto:info@npfd1.com" className="btn btn-outline-light btn-floating m-1" role="button" target="_blank" rel="noopener noreferrer">
                <i className="fas fa-envelope"></i>
              </a>
              <a href="https://wa.me/+923341111773" className="btn btn-outline-light btn-floating m-1" role="button" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-whatsapp"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <LazyFloatingWhatsapp />
      <Footer />
      </Suspense>
    </div>
  );
};

export default Contactus;
