import React, { Suspense, useEffect, useState, lazy } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Popup from '../components/Pricing/Pricepopup.js';
import Whatsapp from '../components/FloatingWhatsapp.js';
import phata from '../assets/phata.gif';
import ReactLoader from '../assets/React-Loading.gif';
import ApplicationForm from '../assets/Application Form NPF D1.pdf';
import Trifold from '../assets/TRIFOLD NPF-D1.pdf';
import PaymentPlan from '../assets/NPF D1 PAYMENTPLAN URDU.pdf';
import '../utils/PaymentPlanButton.css';
import '../styles/Pricing.css';
import {Helmet} from 'react-helmet-async';

// Lazy load PricingCard component
const LazyPricingCard = lazy(() => import('../components/Pricing/PricingCard'));
const LazyPricingCardPolice = lazy(() => import('../components/Pricing/PricingCardPolice.js'));


const Pricing = () => {
  const [showPopup, setShowPopup] = useState(true);

  useEffect(() => {
    document.title = 'NPF-D1 Capital Park City | Pricing Plan';
  },[]);
  const handleClosePopup = () => {
    setShowPopup(false);
  };
  return (
    <div>
      <Helmet>
        <title>NPF-D1 Capital Park City | Pricing Plan</title>
        <meta name="description" content="NPF-D1 Capital Park City have the Most Affordable Pricing Plan."/>
        <link rel='canonical' href='https://d1capitalparkcity.com/pricing'/>
      </Helmet>
      <Suspense fallback={<div style={{ backgroundColor:"#000", width:"100%", height:"100vh" }}><img style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', padding: '20px', borderRadius: '8px' }} src={ReactLoader} alt="Loading..." /></div>}>
      <Header />
      <div className="phata">
        <img src={phata} alt="Phata" />
      </div>

      <h1 className='h1stake'>MOST AFFORDABLE PRICES</h1>
      <div className='Plan'>
  
        {/* <PaymentPlan />*/}
      </div>

      <br/><h3 style={{ padding:'5px', borderBottom:'1px solid #FFB302', display: 'inline-block'}}>Download the Following:</h3>
        <div style={{ margin:'10px'}}>

        <a style={{ margin:'10px'}} className='btn42-43 btn-42' href={ApplicationForm} download="Application Form NPFD1.pdf">Application Form</a>
        <a style={{ margin:'10px'}}  className='btn42-43 btn-42' href={Trifold} download="Flyer NPFD1.pdf">Flyer</a >
        <a  style={{ margin:'10px'}} className='btn42-43 btn-42' href={PaymentPlan} download="Payment Plan NPFD1.pdf">Payment Plan</a >

        </div>
        <p style={{fontSize:'20px', padding:'20px',color:'red',}}>Please send your filled <b>'Application Form'</b> along with payment to<br/><b>National Police Foundation HQ</b>, G-10/4, Mauve Area, Islamabad.</p>

      {/* Wrap lazy-loaded component with Suspense */}
        <div>
        <h1 style={{ padding:'5px', borderBottom:'1px solid #FFB302', display: 'inline-block'}}>For NPF Members/Police</h1>

          {/* Render lazy-loaded component */}
          <LazyPricingCardPolice />
        </div>
        <div>
        <h1 style={{ padding:'5px', borderBottom:'1px solid #FFB302', display: 'inline-block'}}>For Non-NPF Members/Police</h1>

          {/* Render lazy-loaded component */}
          <LazyPricingCard />
        </div>
         {/* {showPopup && (
          <>
            
            <div style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black
              backdropFilter: 'blur(5px)', // Apply blur effect
              zIndex: 999, // Ensure backdrop is below popup
            }} />
          
            <div style={{
              position: 'fixed',
              width:'100%',
              top: '50%',
              zIndex: 1000, // Ensure popup is above backdrop
            }}>
              <Popup onClose={handleClosePopup} />
            </div>
          </>
        )}*/}
        
          {showPopup && <Popup onClose={handleClosePopup} />}

      <Whatsapp />
      <Footer />
      </Suspense>
    </div>
  );
}

export default Pricing;
