import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Header.css';
import { IKImage } from 'imagekitio-react';

const Header = () => {
  const [zIndex, setZIndex] = useState(1);

  const handleButtonClick = () => {
    // Toggle zIndex between 1 and 102 on button click
    setZIndex(prevZIndex => (prevZIndex === 1 ? 102 : 1));
  };

  return (
    <div className="navbar-container" >
      <nav className={`navbar navbar-expand-lg bg-body-tertiary ${zIndex === 102 ? 'higher-zIndex' : ''}`}>
        <div className="container-fluid">
          
        <IKImage
    path="/NpfD1Logo.png?updatedAt=1720023445767" 
    publicKey= 'public_qNiTcGcX9mFOfedWVQzxsR5iKJE='
  urlEndpoint= 'https://ik.imagekit.io/urafoew8x'  
className="logo-image" />
          <Link className="navbar-brand" to="/">
            <span className="brand-d1">NPF-D1</span> CAPITAL PARK CITY
          </Link>
          <button 
            className="navbar-toggler navbar-dark bg-transparent" 
            type="button" 
            data-bs-toggle="collapse" 
            data-bs-target="#navbarNav" 
            aria-controls="navbarNav" 
            aria-expanded="false" 
            aria-label="Toggle navigation" 
            onClick={handleButtonClick} // Call handleButtonClick on button click
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link" to="/">Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about-us">About Us</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact-us">Contact Us</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/pricing">Pricing</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/gallery">Gallery</Link>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://npf.org.pk/membership.htm" target="_blank" rel="noopener noreferrer">
                  NPF Membership Drive
                </a>
              </li>

            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
  
};

export default Header;
